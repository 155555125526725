.App {
  text-align: center;
  background-image: url('../../public/background.png');
  background-repeat: repeat;
  height: 100vh;
  width: 100vw;
  padding: 20px;
}

.my-container {
    padding: 5px !important;
    background-color: white;
    border: 2px solid black !important;
}

.my-img {
    height: 80px;
    width: 80px;
    image-rendering: pixelated;
}

.my-btn {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
}

.my-link {
    text-decoration: none;
    color: black
}

.my-link:hover {
    text-decoration: none;
    color: black;
}

.grow { 
    transition: all .2s ease-in-out; 
}

.grow:hover { 
    transform: scale(1.1); 
}

.my-cursor {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer!important;
}

.my-label {
    margin:0px
}

.customeTheme{
    opacity:1 !important;
    border-color:#000 !important;
    border-style:solid !important;
    border-width:4px !important
}